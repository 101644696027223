import React, { useContext, useEffect } from "react";

import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { isBrowser } from "@/utils/env";
import { getUrlParameterByKey } from "@/utils/urlUtils";

import Loader from "../common/loader";

/**
 * Эта страница работает только для платежей через Robokassa. (новая карта)
 *
 * Что делает страница:
 * - Извлекает идентификатор платежа из URL, переданный от Robokassa через параметры InvId.
 * - Сохраняет этот идентификатор платежа в состоянии setUnfinishedPaymentId "UserContext".
 * - Запускаем пуллинг-запрос, передавая ID платежа.
 */

const PaymentProcessing = () => {
  const { setUnfinishedPaymentId, unfinishedPaymentId } = useContext<UserContextType>(UserContext);

  useEffect(() => {
    if (!isBrowser()) return;

    const invIdParam = getUrlParameterByKey("InvId");
    if (invIdParam) {
      setUnfinishedPaymentId(Number(invIdParam));
    }
  }, [setUnfinishedPaymentId]);

  if (unfinishedPaymentId) {
    return <Loader />;
  }

  return null;
};

export default PaymentProcessing;
